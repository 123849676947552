import generateColorData from "../../helpers/generateColorData";
import { ColorScale } from "../../types";

const yellow: ColorScale<"yellow"> = {
  yellow1: {
    name: "yellow1",
    data: generateColorData("yellow1", { h: 49, s: 54, l: 98, a: 1 }),
  },
  yellow2: {
    name: "yellow2",
    data: generateColorData("yellow2", { h: 49, s: 100, l: 95, a: 1 }),
  },
  yellow3: {
    name: "yellow3",
    data: generateColorData("yellow3", { h: 49, s: 100, l: 90, a: 1 }),
  },
  yellow4: {
    name: "yellow4",
    data: generateColorData("yellow4", { h: 49, s: 100, l: 86, a: 1 }),
  },
  yellow5: {
    name: "yellow5",
    data: generateColorData("yellow5", { h: 49, s: 97, l: 82, a: 1 }),
  },
  yellow6: {
    name: "yellow6",
    data: generateColorData("yellow6", { h: 49, s: 89, l: 76, a: 1 }),
  },
  yellow7: {
    name: "yellow7",
    data: generateColorData("yellow7", { h: 49, s: 80, l: 68, a: 1 }),
  },
  yellow8: {
    name: "yellow8",
    data: generateColorData("yellow8", { h: 49, s: 83, l: 65, a: 1 }),
  },
  yellow9: {
    name: "yellow9",
    data: generateColorData("yellow9", { h: 49, s: 100, l: 58, a: 1 }),
  },
  yellow10: {
    name: "yellow10",
    data: generateColorData("yellow10", { h: 49, s: 92, l: 50, a: 1 }),
  },
  yellow11: {
    name: "yellow11",
    data: generateColorData("yellow11", { h: 49, s: 91, l: 49, a: 1 }),
  },
  yellow12: {
    name: "yellow12",
    data: generateColorData("yellow12", { h: 49, s: 55, l: 13, a: 1 }),
  },
  yellow13: {
    name: "yellow13",
    data: generateColorData("yellow13", { h: 49, s: 55, l: 13, a: 1 }),
  },
};

export default yellow;
