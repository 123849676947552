import { FontSizeTokens, SIZE_UNIT } from "./types";

const FontSizes: FontSizeTokens = {
  web: {
    h100: {
      name: "h100",
      description: "Small text, usually supporting body text or labels.",
      data: {
        size: 0.75,
        sizeUnit: SIZE_UNIT,
        lineHeight: 1.375,
        letterSpacing: 0,
      },
    },
    h200: {
      name: "h200",
      description: "UI and paragraph text.",
      data: {
        size: 0.875,
        sizeUnit: SIZE_UNIT,
        lineHeight: 1.464285,
        letterSpacing: 0,
      },
    },
    h300: {
      name: "h300",
      description: "Input field text size.",
      data: {
        size: 1,
        sizeUnit: SIZE_UNIT,
        lineHeight: 1.5,
        letterSpacing: 0,
      },
    },
    h400: {
      name: "h400",
      description: "Subtitles, modal titles etc.",
      data: {
        size: 1.25,
        sizeUnit: SIZE_UNIT,
        lineHeight: 1.2,
        letterSpacing: -0.003,
      },
    },
    h500: {
      name: "h500",
      description: "Oversized Screen titles. Use in moderation.",
      data: {
        size: 1.5,
        sizeUnit: SIZE_UNIT,
        lineHeight: 1.30004,
        letterSpacing: -0.006,
      },
    },
    h600: {
      name: "h600",
      description: "Used for page titles. Use only once per page.",
      data: {
        size: 2,
        sizeUnit: SIZE_UNIT,
        lineHeight: 1.2,
        letterSpacing: -0.008,
      },
    },
    h700: {
      name: "h700",
      description: "Add description here.",
      data: {
        size: 2.8125,
        sizeUnit: SIZE_UNIT,
        lineHeight: 1.06667,
        letterSpacing: -0.01,
      },
    },
    h800: {
      name: "h800",
      description:
        "Use for intentionally obtrusive text like fullscreen loading messages.",
      data: {
        size: 3.5,
        sizeUnit: SIZE_UNIT,
        lineHeight: 1.2,
        letterSpacing: -0.01,
      },
    },
    h900: {
      name: "h900",
      description: "Oversized Screen titles. Use in moderation.",
      data: {
        size: 7,
        sizeUnit: SIZE_UNIT,
        lineHeight: 1.2,
        letterSpacing: -0.01,
      },
    },
  },
  reactNative: {
    h100: {
      name: "h100",
      description: "Small text, usually supporting body text or labels.",
      data: {
        size: 12,
        lineHeight: 20,
        letterSpacing: 0,
      },
    },
    h200: {
      name: "h200",
      description: "UI and paragraph text.",
      data: {
        size: 14,
        lineHeight: 22,
        letterSpacing: 0,
      },
    },
    h300: {
      name: "h300",
      description: "Input field text size.",
      data: {
        size: 16,
        lineHeight: 24,
        letterSpacing: 0,
      },
    },
    h400: {
      name: "h400",
      description: "Subtitles, modal titles etc.",
      data: {
        size: 18,
        lineHeight: 28,
        letterSpacing: -0.003,
      },
    },
    h500: {
      name: "h500",
      description: "Oversized Screen titles. Use in moderation.",
      data: {
        size: 25,
        lineHeight: 36,
        letterSpacing: -0.006,
      },
    },
    h600: {
      name: "h600",
      description: "Used for page titles. Use only once per page.",
      data: {
        size: 31,
        lineHeight: 44,
        letterSpacing: -0.008,
      },
    },
    h700: {
      name: "h700",
      description: "Add description here.",
      data: {
        size: 40,
        lineHeight: 54,
        letterSpacing: -0.01,
      },
    },
    h800: {
      name: "h800",
      description:
        "Use for intentionally obtrusive text like fullscreen loading messages.",
      data: {
        size: 50,
        lineHeight: 64,
        letterSpacing: -0.01,
      },
    },
    h900: {
      name: "h900",
      description: "Oversized Screen titles. Use in moderation.",
      data: {
        size: 95,
        lineHeight: 114,
        letterSpacing: -0.01,
      },
    },
  },
};

export default FontSizes;
