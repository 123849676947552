import {
  colorUseCases,
  getColorScaleValueByUseCase,
  styled,
} from "@vitality-ds/system";

import DocsFlex from "../../../../../DocsFlex";

const { backgrounds } = colorUseCases;

export const Image = styled("img", {
  width: "110px",
});

export const LogoFlex = styled(DocsFlex, {
  a: {
    textDecoration: "none",
    fontSize: 0,
    margin: "-$md",
    padding: "$md",
    display: "flex",
    borderRadius: "$default",
    border: "none",
    span: {
      /**
       * Position the words "Design System" optically with the logo
       */
      marginTop: "-$sm",
      marginLeft: "$xs",
    },
    "&:hover": {
      backgroundColor: getColorScaleValueByUseCase(
        "neutral",
        backgrounds.uiElement_hovered
      ),
    },
    "&::selection": {
      backgroundColor: getColorScaleValueByUseCase(
        "neutral",
        backgrounds.uiElement_active
      ),
    },
    "&:focus": {
      boxShadow: "0 0 0 $space$outlineWidth $colors$cyan7",
      outline: "none",
    },
  },
  marginTop: 4,
});
