import { Colord, colord, extend, HslaColor } from "colord";
import hwbPlugin from "colord/plugins/hwb";

import { ColorValue } from "../types";

extend([hwbPlugin]);

const generateRgbaString: (colordInstance: Colord) => string = (
  colordInstance
) => colordInstance.toRgbString();

const generateHslaString: (colordInstance: Colord) => string = (
  colordInstance
) => colordInstance.toHslString();

const generateHwbString: (colordInstance: Colord) => string = (
  colordInstance
) => colordInstance.toHwbString();

const generateHexString: (colordInstance: Colord) => string = (
  colordInstance
) => colordInstance.toHex();

const constructColorInstance = (input: HslaColor) => colord(input);

const generateColorData = (
  codeName: string,
  colorObj: HslaColor
): ColorValue => {
  const colordInstance = constructColorInstance(colorObj);
  return {
    codeName,
    hsla: colorObj,
    rgbaString: generateRgbaString(colordInstance),
    hslaString: generateHslaString(colordInstance),
    hwbString: generateHwbString(colordInstance),
    hexString: generateHexString(colordInstance),
  };
};

export default generateColorData;
