import generateColorData from "../../helpers/generateColorData";
import { ColorScale } from "../../types";

const greyA: ColorScale<"greyA"> = {
  greyA1: {
    name: "greyA1",
    data: generateColorData("greyA1", { h: 291, s: 2, l: 99, a: 1 }),
  },
  greyA2: {
    name: "greyA2",
    data: generateColorData("greyA2", { h: 291, s: 34, l: 21, a: 0.03 }),
  },
  greyA3: {
    name: "greyA3",
    data: generateColorData("greyA3", { h: 291, s: 35, l: 18, a: 0.06 }),
  },
  greyA4: {
    name: "greyA4",
    data: generateColorData("greyA4", { h: 300, s: 55, l: 11, a: 0.08 }),
  },
  greyA5: {
    name: "greyA5",
    data: generateColorData("greyA5", { h: 300, s: 46, l: 8, a: 0.1 }),
  },
  greyA6: {
    name: "greyA6",
    data: generateColorData("greyA6", { h: 270, s: 56, l: 14, a: 0.14 }),
  },
  greyA7: {
    name: "greyA7",
    data: generateColorData("greyA7", { h: 300, s: 12, l: 20, a: 0.2 }),
  },
  greyA8: {
    name: "greyA8",
    data: generateColorData("greyA8", { h: 295, s: 30, l: 21, a: 0.44 }),
  },
  greyA9: {
    name: "greyA9",
    data: generateColorData("greyA9", { h: 296, s: 19, l: 23, a: 0.7 }),
  },
  greyA10: {
    name: "greyA10",
    data: generateColorData("greyA10", { h: 291, s: 24, l: 18, a: 0.8 }),
  },
  greyA11: {
    name: "greyA11",
    data: generateColorData("greyA11", { h: 294, s: 34, l: 9, a: 0.78 }),
  },
  greyA12: {
    name: "greyA12",
    data: generateColorData("greyA12", { h: 270, s: 16, l: 9, a: 1 }),
  },
  greyA13: {
    name: "greyA13",
    data: generateColorData("greyA13", { h: 0, s: 0, l: 99, a: 1 }),
  },
};

export default greyA;
