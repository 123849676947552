import generateColorData from "../../helpers/generateColorData";
import { ColorScale } from "../../types";

const pink: ColorScale<"pink"> = {
  pink1: {
    name: "pink1",
    data: generateColorData("pink1", { h: 335, s: 20, l: 9, a: 1 }),
  },
  pink2: {
    name: "pink2",
    data: generateColorData("pink2", { h: 335, s: 32, l: 11, a: 1 }),
  },
  pink3: {
    name: "pink3",
    data: generateColorData("pink3", { h: 335, s: 42, l: 16, a: 1 }),
  },
  pink4: {
    name: "pink4",
    data: generateColorData("pink4", { h: 335, s: 47, l: 19, a: 1 }),
  },
  pink5: {
    name: "pink5",
    data: generateColorData("pink5", { h: 335, s: 50, l: 21, a: 1 }),
  },
  pink6: {
    name: "pink6",
    data: generateColorData("pink6", { h: 335, s: 55, l: 25, a: 1 }),
  },
  pink7: {
    name: "pink7",
    data: generateColorData("pink7", { h: 336, s: 62, l: 30, a: 1 }),
  },
  pink8: {
    name: "pink8",
    data: generateColorData("pink8", { h: 336, s: 74, l: 39, a: 1 }),
  },
  pink9: {
    name: "pink9",
    data: generateColorData("pink9", { h: 339, s: 84, l: 62, a: 1 }),
  },
  pink10: {
    name: "pink10",
    data: generateColorData("pink10", { h: 336, s: 80, l: 57, a: 1 }),
  },
  pink11: {
    name: "pink11",
    data: generateColorData("pink11", { h: 341, s: 90, l: 67, a: 1 }),
  },
  pink12: {
    name: "pink12",
    data: generateColorData("pink12", { h: 332, s: 87, l: 96, a: 1 }),
  },
  pink13: {
    name: "pink13",
    data: generateColorData("pink13", { h: 335, s: 20, l: 9, a: 1 }),
  },
};

export default pink;
