import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
import { BaseSortableIconContainer } from "../../SortableIcon/styled";
import { BaseSortedIconContainer } from "../../SortedIcon/styled";

/**
 * These styles are applied to React-Table-Libary's `HeaderCellSort` component,
 * which renders a <button> containing the sortable icons. Because that button
 * receives the hover state etc, we need to use its hover selector to then
 * apply styles to the sortable icons.
 */
export default css({
  all: "unset",
  "&:hover": _defineProperty(_defineProperty({}, "& ".concat(BaseSortedIconContainer), {
    color: getColorScaleValueByUseCase("neutral", colorUseCases.text.hiContrast)
  }), "& ".concat(BaseSortableIconContainer), {
    color: getColorScaleValueByUseCase("neutral", colorUseCases.text.lowContrast)
  })
});