exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-audit-trail-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/audit-trail.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-audit-trail-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-avatar-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/avatar.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-avatar-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-badge-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/badge.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-badge-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-box-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/box.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-box-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-breadcrumbs-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/breadcrumbs.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-breadcrumbs-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-button-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/button.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-button-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-callout-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/callout.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-callout-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-checkbox-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/checkbox.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-checkbox-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-chip-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/chip.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-chip-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-combo-button-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/combo-button.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-combo-button-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-currency-input-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/currency-input.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-currency-input-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-date-picker-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/date-picker.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-date-picker-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-date-range-picker-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/date-range-picker.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-date-range-picker-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-dialog-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/dialog.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-dialog-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-divider-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/divider.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-divider-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-dropdown-menu-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/dropdown-menu.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-dropdown-menu-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-duration-input-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/duration-input.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-duration-input-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-empty-state-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/empty-state.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-empty-state-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-flex-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/flex.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-flex-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-form-field-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/form-field.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-form-field-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-icon-button-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/icon-button.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-icon-button-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-icons-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/icons.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-icons-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-linear-progress-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/linear-progress.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-linear-progress-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-link-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/link.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-link-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-modal-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/modal.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-modal-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-password-input-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/password-input.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-password-input-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-popover-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/popover.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-popover-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-radio-buttons-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/radio-buttons.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-radio-buttons-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-search-input-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/search-input.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-search-input-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-search-select-input-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/search-select-input.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-search-select-input-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-select-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/select.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-select-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-shortcuts-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/shortcuts.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-shortcuts-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-sidebar-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/sidebar.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-sidebar-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-skeleton-box-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/skeleton-box.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-skeleton-box-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-spinner-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/spinner.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-spinner-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-stack-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/stack.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-stack-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-status-badge-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/status-badge.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-status-badge-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-switch-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/switch.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-switch-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-table-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/table.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-table-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-tabs-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/tabs.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-tabs-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-text-input-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/text-input.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-text-input-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-textarea-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/textarea.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-textarea-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-time-picker-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/time-picker.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-time-picker-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-toaster-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/toaster.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-toaster-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-tooltip-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/tooltip.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-tooltip-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-truncate-content-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/truncate-content.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-truncate-content-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-typography-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/typography.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-typography-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-vitality-provider-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/components/vitality-provider.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-components-vitality-provider-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-foundations-colour-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/foundations/colour.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-foundations-colour-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-foundations-experience-principles-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/foundations/experience-principles.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-foundations-experience-principles-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-foundations-tokens-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/foundations/tokens.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-foundations-tokens-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-foundations-why-vitality-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/foundations/why-vitality.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-foundations-why-vitality-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-guides-getting-started-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/guides/getting-started.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-guides-getting-started-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-guides-styling-components-in-code-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/guides/styling-components-in-code.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-guides-styling-components-in-code-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-guides-theming-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/guides/theming.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-guides-theming-mdx" */),
  "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-patterns-forms-mdx": () => import("./../../../src/templates/docs-page-layout.tsx?__contentFilePath=/home/runner/work/vitality/vitality/docs/src/docs/patterns/forms.mdx" /* webpackChunkName: "component---src-templates-docs-page-layout-tsx-content-file-path-src-docs-patterns-forms-mdx" */)
}

