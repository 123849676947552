import { RadiiType } from "./types";

// eslint-disable-next-line import/prefer-default-export
export const Radii: RadiiType = {
  default: {
    name: "default",
    description: "Typically used on buttons, inputs and small containers.",
    data: {
      value: 4,
    },
  },
  large: {
    name: "large",
    description:
      "Used for larger container components like modal dialogs, cards etc.",
    data: {
      value: 8,
    },
  },
  rounded: {
    name: "rounded",
    description:
      "Used for ensuring circular corners regardless of container width/height.",
    data: {
      value: 99999,
    },
  },
};
