import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
import { BaseShortcut } from "../../../../Shortcuts/styled";
import { BaseDropdownMenuItemIcon } from "../../IconContainer/styled";
var text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds;
export default css(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({
  cursor: "default",
  padding: "$sm $md",
  fontSize: "$body",
  display: "flex",
  gap: "$lg",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "$default",
  color: getColorScaleValueByUseCase("primary", text.hiContrast),
  userSelect: "none",
  svg: {
    marginTop: 0
  }
}, "".concat(BaseDropdownMenuItemIcon, ", ").concat(BaseShortcut), {
  color: getColorScaleValueByUseCase("neutralA", text.lowContrast)
}), "&[aria-disabled='true']", _defineProperty({}, "&, ".concat(BaseDropdownMenuItemIcon, ", ").concat(BaseShortcut), {
  color: getColorScaleValueByUseCase("neutral", text.disabled),
  pointerEvents: "none"
})), "&:hover:not([aria-disabled='true']), &:focus", _defineProperty(_defineProperty({
  backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid)
}, "&, ".concat(BaseDropdownMenuItemIcon, ", ").concat(BaseShortcut), {
  color: getColorScaleValueByUseCase("primary", text.onSolidBackgrounds)
}), "outline", "none")), "&:active:not([aria-disabled='true'])", {
  backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid_active)
}), "&[data-state='open']", {
  backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElement_active)
}), "variants", {
  indentContent: {
    "true": {
      paddingLeft: "$xl"
    }
  }
}));