import { ElevationsType } from "./types";

// eslint-disable-next-line import/prefer-default-export
export const Elevations: ElevationsType = {
  sm: {
    name: "Small",
    description: "Subtle elevation to indicate separation from the background.",
    data: [
      {
        type: "DROP_SHADOW",
        visible: true,
        blendMode: "NORMAL",
        color: {
          r: 0,
          g: 0.2235,
          b: 0.2549,
          a: 0.08,
        },
        offset: {
          x: 0,
          y: 0,
        },
        radius: 0,
        spread: 1,
      },
      {
        type: "DROP_SHADOW",
        visible: true,
        blendMode: "NORMAL",
        color: {
          r: 0,
          g: 0.2235,
          b: 0.2549,
          a: 0.32,
        },
        offset: {
          x: 0,
          y: 4,
        },
        radius: 4,
        spread: -4,
      },
    ],
    dataRN: {
      elevation: 2,
      shadowOffset: { width: 0, height: 1 },
      shadowRadius: 2,
      shadowOpacity: 0.3,
      shadowColor: "#003941",
    },
  },
  md: {
    name: "Medium",
    description:
      "Commonly used for floating elements like dropdowns and popovers.",
    data: [
      {
        type: "DROP_SHADOW",
        visible: true,
        blendMode: "NORMAL",
        color: {
          r: 0,
          g: 0.2235,
          b: 0.2549,
          a: 0.08,
        },
        offset: {
          x: 0,
          y: 0,
        },
        radius: 0,
        spread: 1,
      },
      {
        type: "DROP_SHADOW",
        visible: true,
        blendMode: "NORMAL",
        color: {
          r: 0,
          g: 0.2235,
          b: 0.2549,
          a: 0.32,
        },
        offset: {
          x: 0,
          y: 8,
        },
        radius: 16,
        spread: -8,
      },
    ],
    dataRN: {
      elevation: 4,
      shadowOffset: { width: 0, height: 5 },
      shadowRadius: 8,
      shadowOpacity: 0.2,
      shadowColor: "#003941",
    },
  },
  lg: {
    name: "Large",
    description:
      "To indicate a greater separation from the app background - common in Dialogs, Modals.",
    data: [
      {
        type: "DROP_SHADOW",
        visible: true,
        blendMode: "NORMAL",
        color: {
          r: 0,
          g: 0.2235,
          b: 0.2549,
          a: 0.08,
        },
        offset: {
          x: 0,
          y: 0,
        },
        radius: 0,
        spread: 1,
      },
      {
        type: "DROP_SHADOW",
        visible: true,
        blendMode: "NORMAL",
        color: {
          r: 0,
          g: 0.2235,
          b: 0.2549,
          a: 0.32,
        },
        offset: {
          x: 0,
          y: 16,
        },
        radius: 24,
        spread: -8,
      },
    ],
    dataRN: {
      elevation: 6,
      shadowOffset: { width: 0, height: 12 },
      shadowRadius: 16,
      shadowOpacity: 0.2,
      shadowColor: "#003941",
    },
  },
};
