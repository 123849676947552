import generateColorData from "../../helpers/generateColorData";
import { ColorScale } from "../../types";

const green: ColorScale<"green"> = {
  green1: {
    name: "green1",
    data: generateColorData("green1", { h: 128, s: 30, l: 7, a: 1 }),
  },
  green2: {
    name: "green2",
    data: generateColorData("green2", { h: 128, s: 44, l: 8, a: 1 }),
  },
  green3: {
    name: "green3",
    data: generateColorData("green3", { h: 128, s: 46, l: 10, a: 1 }),
  },
  green4: {
    name: "green4",
    data: generateColorData("green4", { h: 128, s: 48, l: 12, a: 1 }),
  },
  green5: {
    name: "green5",
    data: generateColorData("green5", { h: 128, s: 49, l: 14, a: 1 }),
  },
  green6: {
    name: "green6",
    data: generateColorData("green6", { h: 128, s: 50, l: 17, a: 1 }),
  },
  green7: {
    name: "green7",
    data: generateColorData("green7", { h: 128, s: 51, l: 21, a: 1 }),
  },
  green8: {
    name: "green8",
    data: generateColorData("green8", { h: 128, s: 51, l: 28, a: 1 }),
  },
  green9: {
    name: "green9",
    data: generateColorData("green9", { h: 128, s: 49, l: 46, a: 1 }),
  },
  green10: {
    name: "green10",
    data: generateColorData("green10", { h: 128, s: 70, l: 39, a: 1 }),
  },
  green11: {
    name: "green11",
    data: generateColorData("green11", { h: 128, s: 50, l: 53, a: 1 }),
  },
  green12: {
    name: "green12",
    data: generateColorData("green12", { h: 128, s: 72, l: 94, a: 1 }),
  },
  green13: {
    name: "green13",
    data: generateColorData("green13", { h: 128, s: 50, l: 98, a: 1 }),
  },
};

export default green;
