import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { css } from "@vitality-ds/system";
import { ButtonIcon } from "../../Button/components/ButtonIcon";
export default css(_defineProperty(_defineProperty(_defineProperty({
  display: "flex",
  gap: 1,
  alignItems: "flex-start"
}, "[data-vitality-component='combo-button-main']", {
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0
}), "[data-vitality-component='combo-button-dropdown']", _defineProperty({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  paddingInline: "$md"
}, "".concat(ButtonIcon), {
  margin: 0
})), "variants", {
  size: {
    compact: _defineProperty({}, "[data-vitality-component='combo-button-dropdown']", {
      paddingInline: "$sm"
    })
  }
}));