import generateColorData from "../../helpers/generateColorData";
import { ColorScale } from "../../types";

const cyan: ColorScale<"cyan"> = {
  cyan1: {
    name: "cyan1",
    data: generateColorData("cyan1", { h: 187, s: 60, l: 7, a: 1 }),
  },
  cyan2: {
    name: "cyan2",
    data: generateColorData("cyan2", { h: 187, s: 71, l: 8, a: 1 }),
  },
  cyan3: {
    name: "cyan3",
    data: generateColorData("cyan3", { h: 187, s: 75, l: 10, a: 1 }),
  },
  cyan4: {
    name: "cyan4",
    data: generateColorData("cyan4", { h: 187, s: 79, l: 12, a: 1 }),
  },
  cyan5: {
    name: "cyan5",
    data: generateColorData("cyan5", { h: 187, s: 82, l: 14, a: 1 }),
  },
  cyan6: {
    name: "cyan6",
    data: generateColorData("cyan6", { h: 187, s: 86, l: 16, a: 1 }),
  },
  cyan7: {
    name: "cyan7",
    data: generateColorData("cyan7", { h: 187, s: 92, l: 20, a: 1 }),
  },
  cyan8: {
    name: "cyan8",
    data: generateColorData("cyan8", { h: 187, s: 100, l: 24, a: 1 }),
  },
  cyan9: {
    name: "cyan9",
    data: generateColorData("cyan9", { h: 187, s: 100, l: 40, a: 1 }),
  },
  cyan10: {
    name: "cyan10",
    data: generateColorData("cyan10", { h: 187, s: 100, l: 37, a: 1 }),
  },
  cyan11: {
    name: "cyan11",
    data: generateColorData("cyan11", { h: 187, s: 100, l: 42, a: 1 }),
  },
  cyan12: {
    name: "cyan12",
    data: generateColorData("cyan12", { h: 187, s: 73, l: 93, a: 1 }),
  },
  cyan13: {
    name: "cyan13",
    data: generateColorData("cyan13", { h: 187, s: 52, l: 98, a: 1 }),
  },
};

export default cyan;
