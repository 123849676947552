import generateColorData from "../../helpers/generateColorData";
import { ColorScale } from "../../types";

const violet: ColorScale<"violet"> = {
  violet1: {
    name: "violet1",
    data: generateColorData("violet1", { h: 317, s: 28, l: 98, a: 1 }),
  },
  violet2: {
    name: "violet2",
    data: generateColorData("violet2", { h: 317, s: 28, l: 95, a: 1 }),
  },
  violet3: {
    name: "violet3",
    data: generateColorData("violet3", { h: 313, s: 25, l: 92, a: 1 }),
  },
  violet4: {
    name: "violet4",
    data: generateColorData("violet4", { h: 309, s: 25, l: 90, a: 1 }),
  },
  violet5: {
    name: "violet5",
    data: generateColorData("violet5", { h: 309, s: 35, l: 88, a: 1 }),
  },
  violet6: {
    name: "violet6",
    data: generateColorData("violet6", { h: 313, s: 26, l: 82, a: 1 }),
  },
  violet7: {
    name: "violet7",
    data: generateColorData("violet7", { h: 312, s: 26, l: 73, a: 1 }),
  },
  violet8: {
    name: "violet8",
    data: generateColorData("violet8", { h: 312, s: 27, l: 48, a: 1 }),
  },
  violet9: {
    name: "violet9",
    data: generateColorData("violet9", { h: 312, s: 40, l: 37, a: 1 }),
  },
  violet10: {
    name: "violet10",
    data: generateColorData("violet10", { h: 312, s: 63, l: 36, a: 1 }),
  },
  violet11: {
    name: "violet11",
    data: generateColorData("violet11", { h: 312, s: 58, l: 28, a: 1 }),
  },
  violet12: {
    name: "violet12",
    data: generateColorData("violet12", { h: 312, s: 100, l: 16, a: 1 }),
  },
  violet13: {
    name: "violet13",
    data: generateColorData("violet13", { h: 317, s: 28, l: 99, a: 1 }),
  },
};

export default violet;
