import ColorScales from "./scales";
import { ThemeColorPalette } from "./types";

export { default as ColorScales } from "./scales";

const { light, dark } = ColorScales;
const { cyan, blue, green, orange, pink, red, grey, greyA, violet } = light;
const {
  blue: blueDark,
  green: greenDark,
  orange: orangeDark,
  pink: pinkDark,
  red: redDark,
  grey: greyDark,
  greyA: greyDarkA,
  cyan: cyanDark,
  violet: violetDark,
} = dark;

export const ThemeColors: ThemeColorPalette = {
  neutral: grey,
  neutralA: greyA,
  primary: cyan,
  accent: pink,
  success: green,
  info: blue,
  warning: orange,
  critical: red,
  brand: violet,
};

export const DarkThemeColors: ThemeColorPalette = {
  neutral: greyDark,
  neutralA: greyDarkA,
  primary: cyanDark,
  accent: pinkDark,
  success: greenDark,
  info: blueDark,
  warning: orangeDark,
  critical: redDark,
  brand: violetDark,
};

export {
  colorUseCases,
  getColorScaleValueByUseCase,
  getColorScaleFromThemeObject,
  getUseCasesByStep,
} from "./helpers";
