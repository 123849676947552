const colorUseCases = {
  backgrounds: {
    uiElement: 3,
    uiElement_hovered: 4,
    uiElement_active: 5,
    uiElement_selected: 4,
    uiElement_focused: 4,
    uiElement_disabled: 2,
    uiElementSolid: 10,
    uiElementSolid_hovered: 9,
    uiElementSolid_active: 11,
    uiElementSolid_selected: 9,
    uiElementSolid_focused: 9,
    uiElementSolid_disabled: 7,
    uiElementOnSurface: 4,
    uiElementOnSurface_hovered: 5,
    uiElementOnSurface_active: 6,

    illustration_stroke: 8,
    illustration_fill: 4,

    blanket: 11,

    app: 1,
    card: 1,
    sidebar: 2,
    subtle: 2,
    surface: 2,
    tableRowAlternate: 2,
  },
  borders: {
    uiElement: 7,
    uiElement_hovered: 8,
    uiElement_active: 10,
    uiElement_selected: 9,
    uiElement_disabled: 6,
    uiElement_focused: 7,
    subtle: 6,
    separator: 6,
    focusRing: 7,
  },
  text: {
    hiContrast: 12,
    lowContrast: 11,
    hiSaturation: 10,
    disabled: 8,
    placeholder: 8,
    onSolidBackgrounds: 13,
  },
} as const;

export default colorUseCases;
