/*
--DO NOT EDIT THIS FILE--
It is generated automatically. More icons can be added
via GitHub (https://github.com/genie-engineering/vitality)
and regenerate it.
*/
import Icon from "./Icon";
import { BaseIcon } from "./Icon/styled";
export default Icon;
export { BaseIcon };
export { default as AccountHolder } from "./AccountHolder";
// Please do not edit this file
export { default as Add } from "./Add";
export { default as AddNote } from "./AddNote";
export { default as AddPhoto } from "./AddPhoto";
export { default as AddressBook } from "./AddressBook";
export { default as AdminNote } from "./AdminNote";
// Please do not edit this file
export { default as Alert } from "./Alert";
export { default as AnnouncementLoud } from "./AnnouncementLoud";
export { default as AnnouncementOff } from "./AnnouncementOff";
export { default as AntenatalRecord } from "./AntenatalRecord";
export { default as AntenatalVisits } from "./AntenatalVisits";
// Please do not edit this file
export { default as AppointmentBook } from "./AppointmentBook";
export { default as AppointmentCancelled } from "./AppointmentCancelled";
export { default as AppointmentConfirmed } from "./AppointmentConfirmed";
export { default as Appointments } from "./Appointments";
export { default as AppointmentWrapUp } from "./AppointmentWrapUp";
// Please do not edit this file
export { default as AssistantReport } from "./AssistantReport";
export { default as Attachment } from "./Attachment";
export { default as AutoReceipted } from "./AutoReceipted";
export { default as Back } from "./Back";
export { default as BankingReport } from "./BankingReport";
// Please do not edit this file
export { default as BankReportRefund } from "./BankReportRefund";
export { default as Billed } from "./Billed";
export { default as BirthPostnatal } from "./BirthPostnatal";
export { default as ChevronLeft } from "./ChevronLeft";
export { default as ChevronRight } from "./ChevronRight";
// Please do not edit this file
export { default as ClaimPending } from "./ClaimPending";
export { default as ClaimSent } from "./ClaimSent";
export { default as ClinicalItemNote } from "./ClinicalItemNote";
export { default as Close } from "./Close";
export { default as Collapse } from "./Collapse";
// Please do not edit this file
export { default as Comment } from "./Comment";
export { default as Completed } from "./Completed";
export { default as ConsultNote } from "./ConsultNote";
export { default as Contact } from "./Contact";
export { default as Copy } from "./Copy";
// Please do not edit this file
export { default as Date } from "./Date";
export { default as Delete } from "./Delete";
export { default as DepositsReport } from "./DepositsReport";
export { default as DiagnosticReport } from "./DiagnosticReport";
export { default as DidNotArrive } from "./DidNotArrive";
// Please do not edit this file
export { default as DocumentEdit } from "./DocumentEdit";
export { default as Done } from "./Done";
export { default as Download } from "./Download";
export { default as Drag } from "./Drag";
export { default as Dropdown } from "./Dropdown";
// Please do not edit this file
export { default as Edit } from "./Edit";
export { default as Email } from "./Email";
export { default as Error } from "./Error";
export { default as Expand } from "./Expand";
export { default as Export } from "./Export";
// Please do not edit this file
export { default as Filter } from "./Filter";
export { default as FinancialReport } from "./FinancialReport";
export { default as FlagOff } from "./FlagOff";
export { default as FlagOn } from "./FlagOn";
export { default as FlashOff } from "./FlashOff";
// Please do not edit this file
export { default as FlashOn } from "./FlashOn";
export { default as FlipCamera } from "./FlipCamera";
export { default as GentuLogo } from "./GentuLogo";
export { default as Help } from "./Help";
export { default as Hospital } from "./Hospital";
// Please do not edit this file
export { default as ImageLibrary } from "./ImageLibrary";
export { default as Import } from "./Import";
export { default as Info } from "./Info";
export { default as Invoice } from "./Invoice";
export { default as InvoiceAdjusted } from "./InvoiceAdjusted";
// Please do not edit this file
export { default as ItemReport } from "./ItemReport";
export { default as Label } from "./Label";
export { default as Legend } from "./Legend";
export { default as Letter } from "./Letter";
export { default as Link } from "./Link";
// Please do not edit this file
export { default as LinkOff } from "./LinkOff";
export { default as Logout } from "./Logout";
export { default as Medications } from "./Medications";
export { default as MedicationsAdverseReactions } from "./MedicationsAdverseReactions";
export { default as MedicationsCustomMedication } from "./MedicationsCustomMedication";
// Please do not edit this file
export { default as MedicationsInfo } from "./MedicationsInfo";
export { default as MedicationsOverride } from "./MedicationsOverride";
export { default as Menu } from "./Menu";
export { default as MenuMoreHorizontal } from "./MenuMoreHorizontal";
export { default as MenuMoreVertical } from "./MenuMoreVertical";
// Please do not edit this file
export { default as MissingInvoices } from "./MissingInvoices";
export { default as MissingReferral } from "./MissingReferral";
export { default as Money } from "./Money";
export { default as MyHealthRecord } from "./MyHealthRecord";
export { default as Notifications } from "./Notifications";
// Please do not edit this file
export { default as Obstetrics } from "./Obstetrics";
export { default as OpenFull } from "./OpenFull";
export { default as OpenNewWindow } from "./OpenNewWindow";
export { default as OrganisationalRemittance } from "./OrganisationalRemittance";
export { default as Overdue } from "./Overdue";
// Please do not edit this file
export { default as Patient } from "./Patient";
export { default as Payment } from "./Payment";
export { default as Pdf } from "./Pdf";
export { default as Pending } from "./Pending";
export { default as PhoneNumber } from "./PhoneNumber";
// Please do not edit this file
export { default as Practitioner } from "./Practitioner";
export { default as Print } from "./Print";
export { default as Procedure } from "./Procedure";
export { default as QuickScript } from "./QuickScript";
export { default as Recall } from "./Recall";
// Please do not edit this file
export { default as ReferralReport } from "./ReferralReport";
export { default as Repeat } from "./Repeat";
export { default as Reports } from "./Reports";
export { default as Request } from "./Request";
export { default as Restart } from "./Restart";
// Please do not edit this file
export { default as Restore } from "./Restore";
export { default as RestrictedOff } from "./RestrictedOff";
export { default as RestrictedOn } from "./RestrictedOn";
export { default as ResultsAndLetters } from "./ResultsAndLetters";
export { default as ReturnToNew } from "./ReturnToNew";
// Please do not edit this file
export { default as ReviewedOff } from "./ReviewedOff";
export { default as ReviewedOn } from "./ReviewedOn";
export { default as ReviewFiles } from "./ReviewFiles";
export { default as Search } from "./Search";
export { default as Send } from "./Send";
// Please do not edit this file
export { default as Settings } from "./Settings";
export { default as Sms } from "./Sms";
export { default as SmsConfirmed } from "./SmsConfirmed";
export { default as SmsError } from "./SmsError";
export { default as SmsSent } from "./SmsSent";
// Please do not edit this file
export { default as Sortable } from "./Sortable";
export { default as SupportCentre } from "./SupportCentre";
export { default as TeleConsult } from "./TeleConsult";
export { default as ToDo } from "./ToDo";
export { default as TodoDoneOff } from "./TodoDoneOff";
// Please do not edit this file
export { default as UnresolvedInvoices } from "./UnresolvedInvoices";
export { default as Upload } from "./Upload";
export { default as Visible } from "./Visible";
export { default as VisibleOff } from "./VisibleOff";
export { default as Waiting } from "./Waiting";
// Please do not edit this file
export { default as WaitingRoom } from "./WaitingRoom";