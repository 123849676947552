export { default as blue } from "./blue";
export { default as cyan } from "./cyan";
export { default as green } from "./green";
export { default as grey } from "./grey";
export { default as greyA } from "./greyA";
export { default as orange } from "./orange";
export { default as pink } from "./pink";
export { default as red } from "./red";
export { default as yellow } from "./yellow";
export { default as violet } from "./violet";
