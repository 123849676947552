import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import { Fallback } from "@radix-ui/react-avatar";
import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds,
  borders = colorUseCases.borders;
export var interactiveStyles = {
  border: "none",
  background: "transparent",
  textDecoration: "none",
  transition: "all 150ms ease",
  cursor: "pointer",
  display: "flex",
  borderRadius: 9999,
  "&:hover": {
    boxShadow: "0 0 0 3px ".concat(getColorScaleValueByUseCase("primary", borders.uiElement_hovered))
  },
  "&:focus": {
    outline: "none",
    boxShadow: "0 0 0 3px ".concat(getColorScaleValueByUseCase("primary", borders.uiElement_focused))
  },
  "&:active": {
    transform: "scale(1.04)",
    boxShadow: "0 0 0 3px ".concat(getColorScaleValueByUseCase("primary", borders.uiElement_active))
  }
};
export var avatarColorVariants = {
  "default": {
    color: getColorScaleValueByUseCase("greyA", text.hiContrast),
    backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement)
  },
  primary: {
    color: getColorScaleValueByUseCase("neutral", text.onSolidBackgrounds),
    backgroundColor: getColorScaleValueByUseCase("primary", backgrounds.uiElementSolid)
  }
};
export default css(_extends({
  $$size: "24px",
  $$fontSize: "12px"
}, avatarColorVariants["default"], _defineProperty(_defineProperty({
  fontFamily: "$default",
  boxSizing: "border-box",
  overflow: "hidden",
  width: "$$size",
  display: "inline-block",
  height: "$$size",
  flexShrink: 0,
  lineHeight: "$$size",
  textAlign: "center",
  fontSize: "$$fontSize",
  borderRadius: 9999,
  textOverflow: "ellipsis"
}, "".concat(Fallback), {
  lineHeight: "1em"
}), "variants", {
  /**
   * The Size of the avatar
   */
  size: {
    xs: {
      $$size: "16px",
      $$fontSize: "8px"
    },
    sm: {
      $$size: "20px",
      $$fontSize: "9px"
    },
    md: {
      $$size: "24px",
      $$fontSize: "12px"
    },
    lg: {
      $$size: "32px",
      $$fontSize: "15px"
    },
    xl: {
      $$size: "40px",
      $$fontSize: "15px"
    }
  },
  /**
   * Optional colour set on the component when displaying initials
   */
  color: {
    primary: _extends({}, avatarColorVariants.primary)
  }
})));