import { css, keyframes } from "@vitality-ds/system";
import EASING_FUNCTION from "../../../../Modal/constants";
var presentToolbar = keyframes({
  "0%": {
    transform: "translateY(-80%)",
    opacity: 0
  },
  "100%": {
    transform: "translateY(calc(-100% - 4px))",
    opacity: "100%"
  }
});
export default css({
  height: "fit-content",
  minHeight: 50,
  alignItems: "start",
  display: "flex",
  gap: "$md",
  flexWrap: "wrap",
  flexDirection: "column",
  width: "100%",
  padding: "$xs",
  boxSizing: "border-box",
  "@bp1": {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  "&[data-animate='true']": {
    animation: "".concat(presentToolbar, " 350ms ").concat(EASING_FUNCTION, " forwards")
  },
  variants: {
    floating: {
      "true": {
        position: "absolute",
        zIndex: "$floatingElements"
      }
    }
  }
});