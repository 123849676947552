import { IconButton, SidebarContext, Stack } from "@vitality-ds/components";
import corePackageJson from "@vitality-ds/components/package.json";
import { Menu } from "@vitality-ds/icons";
import { IconType } from "@vitality-ds/icons/src/Icon/types";
import {
  colorUseCases,
  getColorScaleValueByUseCase,
} from "@vitality-ds/system";
import React from "react";

import DocsBox from "../../../DocsBox";
import DocsFlex from "../../../DocsFlex";
import { Github } from "../../../DocsIcons";
import MagentusLogo from "../../../MagentusLogo";
import Search from "../../../Search";
import { WRAPPER_WIDTH } from "../../../Wrapper/constants";
import Container from "../Container";
import Inner from "../Inner";
import ThemeToggleButton from "../ThemeToggleButton";
import Logo from "./components/Logo";
import { Hamburger, HamburgerWrapper, MagentusLogoLink } from "./styled";
import { StandardHeaderProps } from "./types";

const { backgrounds, text } = colorUseCases;

function StandardHeader({
  toggleSidebarOpen,
  sidebarOpen,
}: StandardHeaderProps): JSX.Element {
  const { isSidebarOpen, TRANSITION_DURATION } =
    React.useContext(SidebarContext);
  return (
    <Container
      as="header"
      css={{
        transition: `border ${TRANSITION_DURATION}ms ease`,
        borderBottom: "1px solid",
        borderBottomColor: isSidebarOpen ? "$neutral7" : "transparent",
      }}
    >
      <Inner css={{ maxWidth: WRAPPER_WIDTH }}>
        <Stack direction="horizontal" align="center" spacing="md">
          <Logo />

          <DocsBox
            as="a"
            href="https://www.npmjs.com/package/@vitality-ds/components"
            target="_blank"
            css={{
              fontSize: "$caption",
              color: getColorScaleValueByUseCase(
                "neutral",
                text.onSolidBackgrounds
              ),
              display: "block",
              padding: "$sm $md",
              backgroundColor: getColorScaleValueByUseCase(
                "neutral",
                backgrounds.uiElementSolid
              ),
              borderRadius: "$rounded",
              textDecoration: "none",
              marginLeft: "$sm",
            }}
          >
            v{corePackageJson.version}
          </DocsBox>

          <IconButton
            href="https://github.com/genie-engineering/vitality"
            target="_blank"
            tooltipContent="Vitality on Github"
            icon={<Github />}
          />
        </Stack>
        <DocsFlex
          css={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "$md",
          }}
        >
          <Search />
          <ThemeToggleButton />
          <MagentusLogoLink href="https://www.magentus.com">
            <MagentusLogo width={150} />
          </MagentusLogoLink>
          <HamburgerWrapper>
            <IconButton
              onClick={toggleSidebarOpen}
              tooltipContent="Toggle Menu"
              icon={
                (
                  <Hamburger isOpen={sidebarOpen}>
                    <Menu />
                  </Hamburger>
                ) as IconType
              }
            />
          </HamburgerWrapper>
        </DocsFlex>
      </Inner>
    </Container>
  );
}

export default StandardHeader;
