import { ThemeContext, Typography } from "@vitality-ds/components";
import { Link as GatsbyLink } from "gatsby";
import React, { useContext } from "react";

import vitalityLogo from "../../../../../../images/vitality.svg";
import { Image, LogoFlex } from "./styled";

function Logo() {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === "dark";
  return (
    <LogoFlex>
      <GatsbyLink to="/">
        <Image
          css={isDarkMode && { filter: "grayscale() invert(100%)" }}
          src={vitalityLogo}
        />
        <Typography
          htmlTag="span"
          variant={{ "@initial": "display400", "@bp2": "display500" }}
          color="lowContrast"
        >
          Design System
        </Typography>
      </GatsbyLink>
    </LogoFlex>
  );
}

export default Logo;
